window.addEventListener("load", function() {
// Slider
    var swiperDataSlider = document.querySelectorAll('[data-slider-container]');

    for (var e in swiperDataSlider) if (swiperDataSlider.hasOwnProperty(e)) {

        //Slider
        var swiper_slider = new Swiper('[data-slider-container="' + swiperDataSlider[e].getAttribute('data-slider-container') + '"]', {
            loop: true,
            slidesPerView: 'auto',

            navigation: {
                nextEl: '[data-slider-arrow="next-' + swiperDataSlider[e].getAttribute('data-slider-container') +'"]',
                prevEl: '[data-slider-arrow="prev-' + swiperDataSlider[e].getAttribute('data-slider-container') +'"]'
            },

            pagination: {
                el: '[data-slider-pagination="'+ swiperDataSlider[e].getAttribute('data-slider-container') +'"]',
                clickable: true
            },

            keyboard: {
                enabled: true
            }
        });

        var element = document.querySelector('[data-slider-container="' + swiperDataSlider[e].getAttribute('data-slider-container') + '"]');
        element.swiper = swiper_slider;
    }

    let three_col_slider = document.querySelectorAll('[data-slider-container="id-2"]');
    Array.prototype.forEach.call(three_col_slider, function (el) {
        let slider = el.swiper;
        slider.params.centeredSlides = true;
        slider.update();
    });
});


$('a[href*="#"]')
// Remove links that don't actually link to anything
    .not('[href="#"]')
    .click(function (event) {
        let headerHeight = $('header').height();

        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - headerHeight - 25
                }, 1500)
            }
        }
    });

// Set up HTML elements into variables
var $overlay	= $('<div id="lightboxOverlay"></div>');
var $image		= $('<img>');
var $close		= $('<i class="fa fa-times"></i>');

// Just more variables
var imageUrl;
var imageAlt;

// Adding HTML stuff
$('body').append($overlay);
$overlay.hide();

// When a user clicks on an image
$('#lightbox img').click(function(){
    imageUrl = $(this).attr('src');
    imageAlt = $(this).attr('alt');

    $overlay.append($image);
    $overlay.append($close);
    $image.attr('src', imageUrl);
    $overlay.fadeIn('1000');
    $image.fadeIn('1000');
    $('body').addClass('overflow-hidden')
});


$close.click( function() {
    $overlay.fadeOut('1000');
    $('body').removeClass('overflow-hidden')
} );

$(window).click(function() {
    $('#lightboxOverlay').fadeOut('1000');
    $('body').removeClass('overflow-hidden');
    $close.trigger("click");
});


$('#lightbox img').click(function(event){
    event.preventDefault();
    event.stopPropagation();
});

$('#lightboxOverlay img').click(function(event){
    event.preventDefault();
    event.stopPropagation();
});